import { Injectable } from '@angular/core';

import axios from 'axios';
import { environment } from 'src/environments/environment';

export type GetCasesParams = {
  client_id?: string;
  debtor_name?: string;
  payee_case_id?: string;
  page?: number;
  per_page?: number;
};

export type CasesTableData = {
  id: string;
  payee_case_reference_id: string;
  started_at: string | null;
  partner: string | null;
  parties: {
    debtor_name: string | null;
    client_name: string;
  };
  state: {
    stage: string;
    state_label_text_color: string;
    state_label_background_color: string;
    state_label: string;
    stage_label: string;
  };
  claim: {
    remaining_amount: number;
    original_amount: number;
    currency: string;
  };
  last_transition: {
    label: string | null;
    happened_at: string | null;
  };
  deadlines: {
    soft: string | null;
    hard: string | null;
  };
  actions: {
    id: string;
    label: string;
    dialog: string;
  }[];
};

export type CasesTableHeader = {
  title: string;
  align: 'center' | 'start';
  key?: keyof CasesTableData;
};

export type GetCasesResponse = {
  headers: CasesTableHeader[];
  // TODO
  sortBy: never[];
  data: CasesTableData[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  async getCases(params: GetCasesParams): Promise<GetCasesResponse> {
    const url = environment.baseUrl + '/api/case';
    const result = await axios.get<GetCasesResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async uploadFilesToCase(caseId: string, attorneys: number[], files: File[]) {
    throw new Error('Unimplemented');
  }
}
